<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" lg="6">
        <h3>앱 타겟 유형</h3>
        <validation-provider v-slot="{ errors }" rules="required">
          <v-radio-group
            class="d-inline-block"
            :value="appsType"
            :error-messages="errors"
            @change="checkAppsType"
          >
            <div
              v-for="(m, index) in TargetAppsTypeList"
              :key="index"
              class="mb-5"
            >
              <v-radio :value="m.value" class="ma-0">
                <template v-slot:label>
                  <div>
                    <span class="title">
                      {{ m.text }}
                      <information-hover
                        :title="m.text"
                        :subTitle="m.comment"
                      />
                    </span>
                  </div>
                </template>
              </v-radio>
            </div>
          </v-radio-group>
        </validation-provider>
        <div v-if="!isAppsTypeNontargetting">
          <h3>앱 카테고리</h3>
          <v-select
            v-model="selectedCategoryId"
            :items="categories"
            item-text="name"
            item-value="id"
            :menu-props="{ bottom: true, offsetY: true }"
            outlined
            @change="getCategoryAppsApi"
            hide-selected
            placeholder="카테고리를 선택하세요."
            :loading="categoryLoading"
            :disabled="categoryLoading"
          ></v-select>
        </div>
        <validation-provider
          v-if="!isAppsTypeNontargetting"
          :rules="{ required: true }"
        >
          <h3>앱 검색 - 개별</h3>
          <v-autocomplete
            ref="appsAC"
            :value="apps"
            :items="appsList"
            :loading="isLoading"
            :search-input.sync="searchString"
            item-text="name"
            hide-no-data
            item-value="package"
            placeholder="앱 이름 또는 정확한 패키지명을 입력하세요."
            multiple
            outlined
            prepend-inner-icon="mdi-magnify"
            return-object
            @change="updateApps"
            @keyup.enter="getAppsApi()"
          >
            <template v-slot:selection="data">
              <v-chip
                :key="JSON.stringify(data.item.name)"
                v-bind="data.attrs"
                :input-value="data.selected"
                :disabled="data.disabled"
                label
                @click:close="data.parent.selectItem(data.item.name)"
                color="secondary"
                class="d-none px-1 py-5"
                style="height:60px;"
              >
                <v-img
                  :src="data.item.image"
                  class="rounded-lg "
                  width="50"
                ></v-img>
                <h3 class="px-3">{{ data.item.name }}</h3>
              </v-chip>
            </template>
            <template v-slot:item="data">
              <template v-if="typeof data.item === 'object'">
                <v-list-item-avatar tile>
                  <v-img
                    v-if="data.item.image"
                    class="rounded-lg"
                    :src="data.item.image"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.name"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </validation-provider>
        <div v-if="!isAppsTypeNontargetting">
          <h3>다중 앱 변환</h3>
          <v-textarea
            v-model="packages"
            outlined
            hide-details
            placeholder="앱 패키지 명을 입력하세요
package1
package2
package3
..."
            clearable
          >
          </v-textarea>
          <v-btn
            class="mt-3 mr-auto"
            color="secondary"
            outlined
            :loading="transformLoading"
            @click="transformAppsApi()"
            ><v-icon>mdi-atom-variant</v-icon> 변환하기</v-btn
          >
        </div>
      </v-col>
      <v-col cols="12" lg="6" v-if="!isAppsTypeNontargetting">
        <div class="d-flex align-center">
          <h3>
            선택된 앱 목록
          </h3>
          <v-tooltip bottom v-if="apps.length > 0">
            <template #activator="{ on: onTooltip }">
              <v-btn
                v-on="{ ...onTooltip }"
                icon
                class="ml-3"
                @click="apps.splice(0, apps.length)"
                ><v-icon>mdi-delete</v-icon>({{ apps.length }})</v-btn
              >
            </template>
            <span>모두 지우기</span>
          </v-tooltip>
        </div>
        <v-hover v-for="(app, index) in apps" :key="index">
          <template v-slot:default="{ hover }">
            <v-chip
              label
              color="secondary"
              class="px-1 py-5 ma-1"
              style="height:60px;"
            >
              <v-img :src="app.image" class="rounded-lg " width="50"></v-img>
              <div class="pa-2" style="position:relative">
                <p class="title mb-0">
                  {{ app.name }}
                </p>
                <span class="subtitle-1" v-if="app.category && app.installed">
                  ({{ app.category }} -
                  {{ app.installed.toLocaleString() }})</span
                >
              </div>

              <v-fade-transition>
                <v-overlay v-if="hover" absolute opacity="0.9">
                  <v-tooltip left>
                    <template #activator="{ on: onTooltip }">
                      <v-btn
                        v-on="{ ...onTooltip }"
                        icon
                        @click="
                          () => {
                            apps.splice(index, 1);
                          }
                        "
                        ><v-icon>mdi-delete</v-icon></v-btn
                      >
                    </template>
                    <span>삭제</span>
                  </v-tooltip>
                </v-overlay>
              </v-fade-transition>
            </v-chip>
          </template>
        </v-hover>

        <!-- <v-chip
          v-for="(app, index) in apps"
          :key="index"
          label
          color="secondary"
          class="px-1 py-5 ma-1"
          style="height:60px;"
        >
          <v-img :src="app.image" class="rounded-lg " width="50"></v-img>
          <h3 class="px-3">{{ app.name }}</h3>
        </v-chip> -->
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { ValidationProvider } from 'vee-validate';
import { mapGetters } from 'vuex';
import InformationHover from '@/components/InformationHover.vue';
import { TargetAppsTypeList } from '@/config/admin/campaign';
export default {
  components: {
    ValidationProvider,
    InformationHover
  },
  data() {
    return {
      appsList: [],
      selectedCategoryId: null,
      categories: [],
      categoryLoading: false,
      searchString: null,
      isLoading: false,
      selectedApps: null,
      packages: null,
      transformLoading: false
    };
  },
  computed: {
    ...mapGetters({
      campaign: 'campaign/campaign'
    }),
    apps() {
      return this.campaign.rs3.apps;
    },
    appsType() {
      return this.campaign.rs3.appsType;
    },
    TargetAppsTypeList() {
      return TargetAppsTypeList;
    },
    isAppsTypeDetargetting() {
      return this.campaign.rs3.appsType === 'detarget';
    },
    isAppsTypeNontargetting() {
      return this.campaign.rs3.appsType === 'nontarget';
    }
  },
  async mounted() {
    this.categories = await this.getCategoriesApi();
  },
  methods: {
    getAppsApi() {
      if (this.isLoading) return;
      this.isLoading = true;
      if (!this.searchString) {
        return false;
      }
      return this.$axios
        .get('/dd/apps', {
          params: {
            ss: this.searchString
          }
        })
        .then(res => {
          const { apps } = res.data;
          this.appsList = [...apps, ...this.appsList];
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => (this.isLoading = false));
    },
    checkAppsType(e) {
      this.campaign.rs3.appsType = e.toString();
      if (this.isAppsTypeNontargetting) {
        // 선택한 앱 목록을 초기화 합니다.
        this.campaign.rs3.apps = [];
        // 선택한 카테고리를 초기화 합니다.
        this.selectedCategoryId = null;
      }
    },
    setApps() {},
    async transformAppsApi() {
      this.transformLoading = true;

      try {
        const packages =
          this.packages === null || this.packages === ''
            ? []
            : this.packages.split('\n');
        if (packages.length > 0 === false) {
          this.$Swal.fire({
            html: '<h3>최소 1개이상의 앱 패키지를 입력하세요</h3>',
            icon: 'error',
            iconHtml: '!',
            confirmButtonText: '확인'
          });
          return false;
        }

        const {
          data: { apps }
        } = await this.$axios.post(`/dd/apps-transform`, {
          packages: packages
        });
        this.updateApps(apps);
      } catch (err) {
        console.error(err);
      } finally {
        this.transformLoading = false;
      }
    },
    async getCategoriesApi() {
      this.categoryLoading = true;
      try {
        const {
          data: { categories }
        } = await this.$axios.get(`/dd/categories`);
        return categories;
      } catch (err) {
        console.error(err);
      } finally {
        this.categoryLoading = false;
      }
    },
    async getCategoryAppsApi() {
      this.categoryLoading = true;
      getCategoryAppsApiTry: try {
        if (this.apps.length >= 20) {
          this.maxErrorAlert();
          break getCategoryAppsApiTry;
        }
        const {
          data: { apps }
        } = await this.$axios.get(`/dd/category-apps`, {
          params: {
            categoryId: this.selectedCategoryId
          }
        });
        // console.log(apps);
        this.updateApps(apps);
      } catch (err) {
        console.error(err);
      } finally {
        this.categoryLoading = false;
        this.$nextTick(() => {
          this.selectedCategoryId = null;
        });
      }
    },
    updateApps(value) {
      // console.log((`value`, value));
      try {
        if (this.apps.length >= 50) {
          throw false;
        }
        // 중복된 패키지 제거
        this.campaign.rs3.apps = [...this.apps, ...value].filter(
          (app, index, self) => {
            return index === self.findIndex(t => t.package === app.package);
          }
        );
      } catch (err) {
        console.error(err);
        this.maxErrorAlert();
      } finally {
        // 선택 항목 동기화
        this.$nextTick(() => {
          this.$refs.appsAC.selectedItems = this.campaign.rs3.apps;
        });
      }
    },
    maxErrorAlert() {
      this.$Swal.fire({
        html: '<h3>최대 허용 앱 갯수(50)를 초과했습니다.</h3>',
        icon: 'error',
        iconHtml: '!'
      });
    }
  }
};
</script>
